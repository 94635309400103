import { ldap } from './ldap/settings'
import { notificationSuccess, notificationError, notificationWarning } from '../utils/notification'

class ForgotPasswordService {
  constructor() {
    this.http = ldap
  }
  
  async get_email(body) {
    return new Promise((res, rej) => {
      this.http.post('/forgot-pwd', body)
      .then(({ data }) => {
        if (data.result) {
          notificationSuccess('Sucesso!', `${data.success}`)
          res(data)
        }
        if (!data.result) {
          notificationError('Erro!', `${data.error}`)
        }
      })
      .catch((err) =>  {
        if (!err.response) {
          notificationWarning(
            'Atenção!',
            'A requisição falhou, tente novamente.</br> Caso persista entrar em contato com o DCC/STIC.',
            10000,
            true
          )
        } else {
          notificationError('Erro!', `${err.response.data.error}`)
        }
        rej(err)
      })
    })
  }
  
  async put_reset_pwd(token, email, body) {
    return new Promise((res, rej) => {
      this.http.put(`/reset-pwd/${token}?email=${email}`, body)
      .then(({ data }) => {
        notificationSuccess('Sucesso!', `${data.success}`)
        res(data)
      })
      .catch(err =>  {
        if (!err.response) {
          notificationWarning(
            'Atenção!',
            'A requisição falhou, tente novamente.</br> Caso persista entrar em contato com o DCC/STIC.',
            10000,
            true
          )
        } 
        if(err.response.data) {
          notificationError('Erro!', `${err.response.data.error}`)
        }
        rej(err)
      });
    })
  }
}

export default ForgotPasswordService
