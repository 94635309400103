<template>
  <div class="card-container">
    <el-card class="card-content">
      <div class="card-title">
        <h1 class="text-3xl text-blue-900 font-bold">Recuperar Senha</h1>
        <p class="text-sm text-gray-500">Esqueceu sua senha? Digite seu e-mail que enviaremos um link para definir uma nova senha.</p>
      </div>
      <el-form :model="email">
        <el-form-item prop="value" :rules="[
          {
            required: true,
            type: 'email',
            message: 'Por favor informe um e-mail válido.',
            trigger: ['blur', 'change'],
          }
        ]">
          <el-input
            v-model="email.value"
            type="email"
            placeholder="Infome seu e-mail cadastrado"
            autocomplete="off"
            size="large"
            @keypress.enter.prevent="submitEmail"
          >
          </el-input>
        </el-form-item>
        
        <el-form-item>
          <div class="button-container">
            <el-button
              class="button"
              type="primary"
              :loading=loading
              :disabled="!email.value"
              size="large"
              @click.prevent="submitEmail"
            >Recuperar</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import ForgotPasswordService from '../services/forgot_password.service'

export default {
  name: "Email",
  data() {
    return {
      email: { value: null },
      loading: false
    }
  },
  mounted() {
    this.forgotPasswordService = new ForgotPasswordService();
  },
  methods: {
    submitEmail() {
      this.loading = true
      this.forgotPasswordService.get_email({ email: this.email.value })
      .then(() => {
        this.$router.push('/')
        this.loading = false
      })
      .catch((err) => {
        console.log(err)
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.card-container {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-content {
  max-width: 500px;
}
.card-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}
.button-container {
  width: 100%;
  display: grid;
  flex-direction: column;
  justify-content: stretch;
}
.button {
  width: 100%;
  font-size: 1.2rem;
}
</style>